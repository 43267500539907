<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="页面类别" name="first">
       <pageClassList ref="page" :classType="0"  v-if="activeName == 'first'"></pageClassList>
      </el-tab-pane>
      <!-- <el-tab-pane label="图片类别" name="second">
        <pageClassList ref="image" :classType="1" v-if="activeName == 'second'"></pageClassList>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import pageClassList from './pageClassList'
export default {
  name: "pageClass",
  components:{
    pageClassList,
  },
  data() {
    return {
      activeName: 'first'
    };
  },
  computed: {
  },
  watch:{

  },
  created(){

  },
  methods:{
    handleClick(tab, event) {
      // if (tab.name == 'first'){
      //   this.$refs.page.classType = 0
      // }else if (tab.name == 'second'){
      //   this.$refs.image.classType = 1
      //
      // }
    }
  }
};
</script>

<style scoped lang='scss'>

</style>
